export const LEAVE_STATUS="LEAVE_STATUS"
export const REJECT_LEAVE_STATUS="REJECT_LEAVE_STATUS"
export const EMPLOYEE_STATUS_UPDATE="EMPLOYEE_STATUS_UPDATE"

export const LEAVE_STATUS_REQUEST="LEAVE_STATUS_REQUEST"
export const LEAVE_STATUS_SUCCESS="LEAVE_STATUS_SUCCESS"
export const LEAVE_STATUS_FAILURE="LEAVE_STATUS_FAILURE" 




export const REJECT_LEAVE_STATUS_REQUEST="REJECT_LEAVE_STATUS_REQUEST"
export const REJECT_LEAVE_STATUS_SUCCESS="REJECT_LEAVE_STATUS_SUCCESS"
export const REJECT_LEAVE_STATUS_FAILURE="REJECT_LEAVE_STATUS_FAILURE" 


export const EMPLOYEE_STATUS_UPDATE_REQUEST="eEMPLOYEE_STATUS_UPDATE_REQUEST"
export const EMPLOYEE_STATUS_UPDATE_SUCCESS="eEMPLOYEE_STATUS_UPDATE_SUCCESS"
export const EMPLOYEE_STATUS_UPDATE_FAILURE="eEMPLOYEE_STATUS_UPDATE_FAILURE" 
