import { COMPONENT, ROW, COLUMN } from "./constants";

const initialData = {
  layout: [
    // {
    //   type: ROW,
    //   id: "row0",
     
    // },
    // {
    //   type: ROW,
    //   id: "row1",
   
    // }
  ],
  components: {
    // component0: { id: "component0", type: "input", content: "Some input" },
    // component1: { id: "component1", type: "image", content: "Some image" },
    // component2: { id: "component2", type: "email", content: "Some email" },
    // component3: { id: "component3", type: "name", content: "Some name" },
    // component4: { id: "component4", type: "phone", content: "Some phone" }
  }
};

export default initialData;
